/* You can add global styles to this file, and also import other style files */
@use "@angular/material" as mat;
@include mat.core();

$custom-app-primary: mat.m2-define-palette(mat.$m2-blue-palette, 600);
$custom-app-secondary: mat.m2-define-palette(mat.$m2-pink-palette, 700);
$custom-app-warn: mat.m2-define-palette(mat.$m2-red-palette, 500);

$custom-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $custom-app-primary,
      accent: $custom-app-secondary,
      warn: $custom-app-warn,
    ),
  )
);

@include mat.all-component-themes($custom-theme);
@include mat.core-theme($custom-theme);

@import "bootstrap/dist/css/bootstrap.min.css";

html,
body {
  height: 100%;
  margin: 0;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f0f0f0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 10px;
}

.mat-drawer-content {
  background-color: #f4f4f4;
}

/* buttons standard styles */
.btn-03-fill {
  background-color: #1e1e1e !important;
  color: white !important;
}

.btn-03-outline {
  background-color: white !important;
  color: #1e1e1e !important;
}

.mat-drawer-content {
  background-color: #f4f4f4;
}

.errorNotifier.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #d22e2e;
}
.successNotifier {
  --mdc-snackbar-container-color: #478c45;
  /* background: rgb(91, 165, 86); */
  // background-color: #478c45;
}

.color-std-title {
  color: #1e1e1e;
}

.button-disabled {
  opacity: 0.6;
}
.spinner-container-std {
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-std-error{
  width: 250px;
  height: 500px;
}
